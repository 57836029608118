<template>
    <MainContainer>
        <div class="forms-presenter">
            <div v-if="busy" class="h-100 d-flex">
                <b-jumbotron
                    class="m-auto text-center"
                    header="Formular wird geladen"
                    header-tag="h2"
                    header-level="5"
                    lead="Die Formulardaten werden geladen..."
                >
                    <div class="text-center pt-4">
                        <Spinner />
                    </div>
                </b-jumbotron>
            </div>
            <div v-if="error.state" class="h-100 d-flex">
                <b-jumbotron
                    class="m-auto"
                    header="Formular nicht erreichbar"
                    header-tag="h2"
                    header-level="5"
                    :lead="error.obj.description"
                >
                    <hr />
                    <template v-if="error.obj.disable_text">
                        <div v-html="error.obj.disable_text"></div>
                    </template>
                    <template v-else>
                        <p>
                            Bei Problemen schreiben Sie bitte eine Nachricht an
                            die entsprechend zuständige Mitarbeiter*in
                        </p>
                        <b-button href="mailto:info@vhs-nrw.de"
                            >Mail versenden</b-button
                        >
                    </template>
                </b-jumbotron>
            </div>
            <div class="form" v-if="form">
                <b-row>
                    <b-col cols="12" lg="6" offset-lg="3" class="form-inner">
                        <header class="form-header">
                            <b-row>
                                <b-col cols="12" lg="8">
                                    <img
                                        alt="LV VHS Logo"
                                        class="logo"
                                        src="@/assets/images/logo_width.png"
                                        width="350"
                                    />
                                </b-col>
                                <b-col cols="12" lg="4" class="small">
                                    <p>
                                        <b
                                            >Landesverband der Volkshochschulen
                                            von NRW e.V.</b
                                        ><br />
                                        Bismarckstraße 98 | 40210 Düsseldorf
                                    </p>

                                    <p>
                                        Fon 0211 542141-0<br />
                                        Fax 0211 542141-50<br />
                                        <a href="mailto:service@vhs-nrw.de"
                                            >service@vhs-nrw.de</a
                                        ><br />
                                        <a
                                            href="https://www.vhs-nrw.de"
                                            target="_blank"
                                            >www.vhs-nrw.de</a
                                        >
                                    </p>
                                </b-col>
                                <b-col cols="12 mt-5">
                                    <h1 class="h4 font-weight-bold">
                                        {{ form.title }}
                                    </h1>
                                </b-col>
                            </b-row>
                        </header>
                        <b-form @submit="handleSubmit">
                            <div
                                v-for="field in form.fields"
                                :key="field.id"
                                class="form-field"
                            >
                                <b-form-group
                                    :size="size"
                                    :state="
                                        getValidationStateForField(field.id)
                                    "
                                >
                                    <template #label>
                                        <template v-if="field.field_label">
                                            <b>{{ field.field_label }}</b>
                                        </template>
                                        <template
                                            v-if="field.field_required == 1"
                                        >
                                            <span class="text-danger">*</span>
                                        </template>
                                        <template v-if="field.field_subtext">
                                            <p
                                                class="small text-secondary mb-0"
                                            >
                                                {{ field.field_subtext }}
                                            </p>
                                        </template>
                                    </template>

                                    <template #description>
                                        <!--<template v-if="field.field_subtext">
                                            {{ field.field_subtext }}
                                        </template>-->
                                    </template>

                                    <template #invalid-feedback>
                                        <p
                                            v-for="feedback in getValidationFeedbackForField(
                                                field.id
                                            )"
                                            :key="feedback"
                                            class="feedback"
                                        >
                                            {{ feedback }}
                                        </p>
                                    </template>

                                    <component
                                        :is="field.component"
                                        :field="field"
                                        :frontend="true"
                                        :state="
                                            getValidationStateForField(field.id)
                                        "
                                        v-model="field.value"
                                    />
                                </b-form-group>
                            </div>
                            <div class="data-privacy-approval">
                                <b-form-group
                                    :size="size"
                                    :state="
                                        getValidationStateForField(
                                            'data_privacy_approval'
                                        )
                                    "
                                >
                                    <template #invalid-feedback>
                                        <p
                                            v-for="feedback in getValidationFeedbackForField(
                                                'data_privacy_approval'
                                            )"
                                            :key="feedback"
                                            class="feedback"
                                        >
                                            {{ feedback }}
                                        </p>
                                    </template>
                                    <b-checkbox
                                        v-model="data_privacy_approval"
                                    />
                                    Die
                                    <b-link
                                        :to="{
                                            path: '/datenschutzerklaerung'
                                        }"
                                        target="_blank"
                                        >Datenschutzerklärung</b-link
                                    >
                                    habe ich gelesen und stimme dieser zu.
                                    <span class="text-danger">*</span>
                                </b-form-group>
                            </div>
                            <div class="notice">
                                <p class="small">
                                    Mit
                                    <span class="text-danger">*</span> markierte
                                    Felder sind Pflichtfelder
                                </p>
                            </div>
                            <b-button
                                type="submit"
                                variant="success"
                                :disabled="busy || submit"
                                >Abschicken</b-button
                            >
                        </b-form>
                    </b-col>
                </b-row>
            </div>
            <b-alert
                class="user-response-alert"
                :show="response.finished"
                :variant="response.success ? 'success' : 'danger'"
                dismissible
                @dismissed="dismissResponseAlert"
            >
                <h4 class="alert-heading">
                    <template v-if="response.success">
                        Ihre Daten wurden erfolgreich übertragen und
                        gespeichert.
                    </template>
                    <template v-else>
                        Es gab einen Fehler bei der Formularübermittlung.
                    </template>
                </h4>
                <hr />
                <div v-html="response.obj.description"></div>
            </b-alert>
        </div>
    </MainContainer>
</template>

<script>
import ApiFactory from "@/api/ApiFactory.js";

import Size from "@/mixins/Size/Size";
import Forms from "@/mixins/Forms/Forms";

import MainContainer from "@/components/MainContainer/MainContainer";
import Spinner from "@/components/Spinner/Spinner";

import InputField from "@/components/FormsCreator/FieldTypes/InputField";
import TextareaField from "@/components/FormsCreator/FieldTypes/TextareaField";
import WYSIWYGField from "@/components/FormsCreator/FieldTypes/WYSIWYGField";
import EmailField from "@/components/FormsCreator/FieldTypes/EmailField";
import GenderField from "@/components/FormsCreator/FieldTypes/GenderField";
import SingleChoiceField from "@/components/FormsCreator/FieldTypes/SingleChoiceField";
import MultipleChoiceField from "@/components/FormsCreator/FieldTypes/MultipleChoiceField";
import DropdownField from "@/components/FormsCreator/FieldTypes/DropdownField";
import CounterField from "@/components/FormsCreator/FieldTypes/CounterField";
import StarRatingField from "@/components/FormsCreator/FieldTypes/StarRatingField";
import TitleField from "@/components/FormsCreator/FieldTypes/TitleField";
import PartingLineField from "@/components/FormsCreator/FieldTypes/PartingLineField.vue";

import "./FormsPresenter.scss";

const formApi = ApiFactory.get("forms");

export default {
    name: "FormsPresenter",
    mixins: [Size, Forms],
    components: {
        MainContainer,
        Spinner,
        InputField,
        TextareaField,
        WYSIWYGField,
        EmailField,
        GenderField,
        SingleChoiceField,
        MultipleChoiceField,
        DropdownField,
        CounterField,
        StarRatingField,
        TitleField,
        PartingLineField
    },
    data() {
        return {
            form: null,
            error: {
                state: false,
                obj: null
            },
            busy: false,
            submit: false,
            response: {
                finished: false,
                success: false,
                obj: {
                    description: ""
                }
            },
            data_privacy_approval: null
        };
    },
    computed: {
        token() {
            return this.$route.params.token;
        }
    },
    created() {
        this.fetch(this.token);
    },
    methods: {
        async fetch(token) {
            this.busy = true;
            const response = await formApi.getPublic(token);
            if (response.ok) {
                const data = response.json.data;
                data.fields = data.fields.sort((a, b) =>
                    a.field_position > b.field_position
                        ? 1
                        : b.field_position > a.field_position
                        ? -1
                        : 0
                );
                this.form = data;
            } else {
                this.error = {
                    state: true,
                    obj: response.json.error
                };
            }
            this.busy = false;
        },
        async handleSubmit(event) {
            event.preventDefault();

            this.submit = true;

            const contents = {};
            this.form.fields.forEach(item => (contents[item.id] = item.value));
            contents.data_privacy_approval = this.data_privacy_approval;

            const response = await formApi.submitAnswer(this.token, {
                contents
            });

            this.response.success = response.ok;
            if (response.ok) {
                this.response.obj = {
                    description: this.form.thank_you_text
                        ? this.form.thank_you_text
                        : "Vielen Dank für das ausfüllen des Formulars"
                };
                if (this.response.success) {
                    this.resetForm();
                }
            } else {
                if (response.json.error.validation) {
                    const validation = response.json.error.validation;
                    this.validation = validation;

                    this.response.obj.description =
                        "Bitte füllen Sie alle Felder korrekt aus.";
                }
            }

            this.response.finished = true;
            this.submit = false;
        },
        resetForm() {
            this.resetValidationFeedback();
            this.form.fields = this.form.fields.map(item => {
                /**
                 * Das MultipleChoiceField muss anders behandelt werden, da der Datentyp ein Array sein muss.
                 */
                if (item.component === 'MultipleChoiceField') {
                    if (item.field_default_value) {
                        item.value = [item.field_default_value];
                    } else {
                        item.value = [];
                    }
                } else {
                    item.value = item.field_default_value;
                }
                return item;
            });
        },
        dismissResponseAlert() {
            this.response.finished = false;
        }
    }
};
</script>
