import { render, staticRenderFns } from "./FormsPresenter.vue?vue&type=template&id=c194b1f8&"
import script from "./FormsPresenter.vue?vue&type=script&lang=js&"
export * from "./FormsPresenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports